import './style.scss';
import { conferenceService, chatService } from '../../../../services';

export default {
    name: 'InteractiveContent',
    props: ['ggdocToDisplay', 'interactiveContents'],
    data() {
        return {
            socket: null,
            currentInteractiveContent: this.ggdocToDisplay,
        };
    },
    async created() {
        const { id } = this.$route.params;
        this.socket = chatService.getSocket(id);
        this.socket.on('next-sondage', (value) => {
            this.currentInteractiveContent = value;
        });
        this.getCurrentInteractiveContents(id);
    },
    methods: {
        async getCurrentInteractiveContents(id) {
            const res = await conferenceService.getCurrentInteractiveContents(id);
            this.currentInteractiveContent = res;
        },
    },
};
