import './style.scss';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import { Title, InteractiveContent } from '../../shared';
import Chat from '../../Chat/Chat.vue';
import { loginService, chatService, conferenceService } from '../../../../services';

export default {
    name: 'AdminViewing',
    components: {
        Title,
        Chat,
        ChevronLeft,
        InteractiveContent,
    },
    data() {
        return {
            ggdocToDisplay: 0,
            conference: {},
            socket: null,
        };
    },
    mounted() {
        this.$root.$on('setConference', (conference) => { this.conference = conference; });
    },
    async created() {
        const { id } = this.$route.params;
        this.conference = await conferenceService.getConference(id);

        this.socket = chatService.getSocket(id);
        this.socket.on('next-sondage', (value) => {
            this.ggdocToDisplay = value;
        });
        this.checkToken();
        this.getCurrentInteractiveContents(id);
    },
    methods: {
        async checkToken() {
            const { data } = await loginService.checkToken(localStorage.getItem('token'));
            const { type } = data;
            if (type !== 'modo' && type !== 'admin') window.location.href = '/';
        },
        async getCurrentInteractiveContents(id) {
            const res = await conferenceService.getCurrentInteractiveContents(id);
            this.ggdocToDisplay = res;
        },
        displayNextSondage() {
            if (this.ggdocToDisplay < this.conference.links.interactiveContents.length - 1) {
                const { id } = this.$route.params;
                this.ggdocToDisplay += 1;
                this.socket.emit('nextSondage', { value: this.ggdocToDisplay, param: id, action: 'next' });
            }
        },
        displayPreviousSondage() {
            if (this.ggdocToDisplay > 0) {
                const { id } = this.$route.params;
                this.ggdocToDisplay -= 1;
                this.socket.emit('nextSondage', { value: this.ggdocToDisplay, param: id, action: 'previous' });
            }
        },
    },
};
