import Vue from 'vue';
import VueCookie from 'vue-cookie';
import App from './App/App.vue';
import router from './router';
import './tailwind.css';

Vue.use(VueCookie);

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
