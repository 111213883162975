import './style.scss';
import Clock from '../../../../assets/Home/Card';

const defaultCard = {
    title: '',
    details: '',
    hour: '',
    id: 0,
};

export default {
    name: 'card',
    props: ['content', 'important'],
    data() {
        return {
            assets: { clock: Clock },
            item: this.content ? this.content : defaultCard,
        };
    },
    computed: {
        getShadows() {
            return this.important === '' ? 'important' : 'shadow-xl';
        },
    },
    methods: {
        goToConference() {
            this.$router.push({
                name: 'ConferenceInfos',
                params: {
                    id: this.content.id,
                },
            });
        },
    },
};
