import './style.scss';
import Logo from '../../../assets/Footer';

export default {
    name: 'main-footer',
    data() {
        return {
            assets: { logo: Logo },
        };
    },
};
