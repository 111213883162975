import './style.scss';
import Swal from 'sweetalert2';
import { loginService } from '../../../services';

export default {
    name: 'Login',
    data() {
        return {
            body: { id: null, password: null },
            isLogged: true,
            typeUser: null,
        };
    },
    created() {
        const token = localStorage.getItem('token');
        // eslint-disable-next-line no-unused-expressions
        token !== null ? this.isLogged = true : this.isLogged = false;
        if (this.isLogged) this.getUserType();
    },
    methods: {
        async submitForm() {
            const { data } = await loginService.login(this.body);
            if (data.status === 200) {
                localStorage.setItem('token', data.data);
                this.getUserType();
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    text: 'Connexion réussie',
                    showConfirmButton: false,
                    timer: 1000,
                });
                this.isLogged = true;
            } else {
                localStorage.removeItem('token');
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    text: 'Mauvais identifiants',
                    showConfirmButton: false,
                    timer: 1000,
                });
                this.isLogged = false;
            }
        },
        disconnect() {
            localStorage.removeItem('token');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                text: 'Déconnexion réussie',
                showConfirmButton: false,
                timer: 1000,
            });
            this.isLogged = false;
        },
        async getUserType() {
            const infoToken = await loginService.checkToken(localStorage.getItem('token'));
            this.typeUser = infoToken.data.type;
        },
        onSubmit() {
            this.submitForm();
        },
    },
};
