import axios from 'axios';
import urlBuilder from './helper/urlBuilder';

async function getConferences() {
    let res;
    try {
        res = await axios.request({
            method: 'GET',
            validateStatus: (status) => status <= 500,
            url: `${urlBuilder()}/conferences`,
        });
    } catch (error) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }

    const { status, data } = res;
    if (status !== 200) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }
    return data;
}

async function getConference(id) {
    let res;
    try {
        res = await axios.request({
            method: 'GET',
            validateStatus: (status) => status <= 500,
            url: `${urlBuilder()}/conference/${id}`,
        });
    } catch (error) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }

    const { status, data } = res;
    if (status !== 200) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }
    return data;
}

async function getCurrentInteractiveContents(id) {
    let res;
    try {
        res = await axios.request({
            method: 'GET',
            validateStatus: (status) => status <= 500,
            url: `${urlBuilder()}/currentInteractiveContents/${id}`,
        });
    } catch (error) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }
    const { status, data } = res;
    if (status !== 200) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }
    return data;
}

export default { getConferences, getConference, getCurrentInteractiveContents };
