import './style.scss';
import profilIcon from '../../../../../assets/Conference/ConferenceInfos/StakeholderCard';

export default {
    name: 'StakeholderCard',
    props: ['stakeholder'],
    data() {
        return {
            assets: { profilIcon },
        };
    },
};
