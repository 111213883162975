import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import {
    About,
    Home,
    Conference,
    ConferenceInfos,
    ConferenceViewing,
    Contact,
    Login,
    ConferenceLocalViewing,
    AdminViewing,
} from '../App/components';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/conference',
        name: 'Conference',
        component: Conference,
        children: [
            {
                path: 'info/:id',
                name: 'ConferenceInfos',
                component: ConferenceInfos,
            },
            {
                path: 'stream/:id',
                name: 'ConferenceViewing',
                component: ConferenceViewing,
            },
            {
                path: 'local/:id',
                name: 'ConferenceLocalViewing',
                component: ConferenceLocalViewing,
            },
            {
                path: 'admin/:id',
                name: 'AdminViewing',
                component: AdminViewing,
            },
        ],
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
