import './style.scss';
import moment from 'moment-timezone';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import { Title } from '../../shared';
import StakeholderCard from './StakeholderCard/StakeholderCard.vue';

export default {
    name: 'ConferenceInfos',
    components: {
        Title,
        StakeholderCard,
        ChevronRight,
    },
    data() {
        return {
            conference: {},
            formattedDate: '',
        };
    },
    mounted() {
        this.$root.$on('setConference', (conference) => {
            this.conference = conference;
            this.formattedDate = moment(new Date(conference.date)).locale('fr').tz('Europe/Paris').format('dddd DD MMMM YYYY [à] HH[h]mm');
        });
    },
    methods: {
        capitalize(str) {
            return str.slice(0, 1).toUpperCase() + str.slice(1);
        },
    },
};
