import Close from 'vue-material-design-icons/Close.vue';
import Logo from '../../../assets/shared';
import './style.scss';
import { Menu } from '../../../assets/Header';

export default {
    name: 'mainHeader',
    props: ['backgroundImage'],
    components: {
        Close,
    },
    data() {
        return {
            assets: {
                logo: Logo,
                menu: Menu,
            },
            item: {
                title: 'bonjour',
                speakers: 'test et test',
            },
            mobileMenuShowed: false,
        };
    },
    computed: {
        styleObject() {
            return this.backgroundImage
                ? {
                    'background-image': `url(${this.backgroundImage})`,
                    filter: 'blur(8px)',
                }
                : {
                    background: 'linear-gradient(143.31deg, #014259 33.12%, #014259 33.13%, #0284B3 100%)',
                };
        },
    },
    methods: {
        toggleMenu() {
            this.mobileMenuShowed = !this.mobileMenuShowed;
        },

        openNav() {
            document.getElementById('overlay').style.width = '100%';
        },

        closeNav() {
            document.getElementById('overlay').style.width = '0%';
        },
    },
};
