import './style.scss';
import swal from 'sweetalert';
import { Title } from '../shared';
import { mailService } from '../../../services';

export default {
    name: 'Contact',
    metaInfo: {
        titleTemplate: '%s | Contact',
    },
    components: {
        Title,
    },
    mounted() {
        this.$emit('showConferenceBanner', false);
        this.changeHeader();
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            question: '',
            checkbox: false,
        };
    },
    methods: {
        resetFrom() {
            this.firstname = '';
            this.lastname = '';
            this.email = '';
            this.question = '';
            this.checkbox = false;
        },
        changeHeader() {
            const header = document.querySelector('#banner');

            header.classList.add('flex-col');
            header.innerHTML = `
                <p class="leading-normal pb-5 lg:pb-10 text-center">
                    Des conférences qui n'attendent <span class="font-bold text-primary">que vous</span> !
                </p>
                <p class="text-base text-center">Contact et renseignements</p>`;
        },

        async submitForm() {
            const status = await mailService.send({
                user: {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                },
                content: this.question,
            });

            if (status !== 200) {
                swal({
                    icon: 'error',
                    text: 'Echec lors de l\'envoi de l\'email, contactez nous sur nos réseaux',
                });
            }
            swal({
                icon: 'success',
                text: 'Email envoyé avec succès !',
            });
            this.resetFrom();
        },

        onSubmit() {
            this.submitForm();
        },
    },
};
