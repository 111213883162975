import moment from 'moment-timezone';

const comparaisonTime = 'day';

export default (c, d) => c.reduce((a, v) => {
    const cd = new Date(v.date);
    if (moment(cd).isBefore(d, comparaisonTime)) return { ...a, previous: [...a.previous, v] };
    if (moment(cd).isSame(d, comparaisonTime)) return { ...a, currents: [...a.currents, v] };
    if (moment(cd).isAfter(d, comparaisonTime)) return { ...a, nexts: [...a.nexts, v] };
    return a;
}, { previous: [], currents: [], nexts: [] });
