import './style.scss';
import { Header, Footer } from './components';

export default {
    name: 'app',
    metaInfo: {
        link: [
            { rel: 'favicon', href: 'http://favicomatic.com/img/favic-o-matic.png' },
        ],
        title: 'Cyberconf',
        titleTemplate: '%s | Accueil',
    },
    components: { Header, Footer },
    data() {
        return {
            assets: {
                bgImage: null,
            },
            colors: {
                primary: '',
                secondary: '',
            },
        };
    },
    mounted() {
        localStorage.primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
        localStorage.secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary');
    },
    methods: {
        setBanner(banner) {
            this.assets.bgImage = banner || null;
        },
    },

};
