import axios from 'axios';
import urlBuilder from './helper/urlBuilder';

async function send(data) {
    let res;
    try {
        res = await axios.request({
            method: 'POST',
            validateStatus: (status) => status <= 500,
            url: `${urlBuilder()}/mail`,
            data,
        });
    } catch (error) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }

    const { status } = res;
    if (status !== 200) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }

    return status;
}

export default { send };
