import './style.scss';

export default {
    name: 'MainTitle',
    props: ['title', 'colorTitle', 'colorDot'],
    data() {
        return {
            item: {
                colorTitle: this.colorTitle,
                colorDot: this.colorDot,
            },
        };
    },

};
