import axios from 'axios';
import urlBuilder from './helper/urlBuilder';

async function login(data) {
    let res;
    try {
        res = await axios.request({
            method: 'POST',
            validateStatus: (status) => status <= 500,
            url: `${urlBuilder()}/login`,
            data,
        });
    } catch (error) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }

    return res;
}

async function checkToken(data) {
    let res;
    try {
        res = await axios.request({
            method: 'GET',
            validateStatus: (status) => status <= 500,
            url: `${urlBuilder()}/token`,
            headers: {
                authorization: `Bearer ${data}`,
            },
        });
    } catch (error) {
        return error;
    }

    const { status } = res;

    if (status === 500) {
        localStorage.removeItem('token');
        return res;
    }

    if (status === 404) {
        return res;
    }

    if (status !== (200 || 204)) {
        throw new Error('Une erreur interne est survenue, merci de réessayer ultérieurement.');
    }

    return res;
}

export default { login, checkToken };
