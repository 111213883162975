import './style.scss';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import { Title, InteractiveContent } from '../../shared';
import Chat from '../../Chat/Chat.vue';

export default {
    name: 'ConferenceViewing',
    components: {
        Title,
        Chat,
        ChevronLeft,
        InteractiveContent,
    },
    data() {
        return {
            conference: {
                links: {
                    interactiveContents: [
                    ],
                },
            },
        };
    },
    mounted() {
        this.$root.$on('setConference', (conference) => { this.conference = conference; });
    },
};
