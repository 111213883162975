import './style.scss';
import { conferenceService } from '../../../services';
import formatSpeakers from './helper/formatSpeakers';

export default {
    name: 'Conference',
    metaInfo: {
        titleTemplate: '%s | Conférence',
    },
    data() {
        return {
            conference: {},
        };
    },
    async created() {
        const { id } = this.$route.params;
        this.conference = await conferenceService.getConference(id);
        this.$root.$emit('setConference', this.conference);
        this.$emit('setBanner', this.conference.img);
        this.changeHeader();
        this.updateMainColors();
    },
    updated() {
        this.$emit('setBanner', this.conference.img);
        this.$root.$emit('setConference', this.conference);
        this.changeHeader();
        this.updateMainColors();
    },
    beforeDestroy() {
        this.resetMainColors();
    },
    methods: {
        changeHeader() {
            const header = document.querySelector('#banner');

            header.classList.add('flex-col');
            header.innerHTML = `
                <p class="leading-normal">
                    ${this.conference.title}
                </p>
                <p class="text-sm md:text-base">Animée par ${formatSpeakers(this.conference.speakers)}</p>`;
        },

        updateMainColors() {
            this.colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
            this.colorSecondary = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary');

            if (this.conference.design !== undefined
                && this.conference.design.mainColor !== undefined
                && this.conference.design.secondColor !== undefined) {
                document.documentElement.style.setProperty('--color-primary', this.conference.design.mainColor);
                document.documentElement.style.setProperty('--color-secondary', this.conference.design.secondColor);
            }
        },

        resetMainColors() {
            document.documentElement.style.setProperty('--color-primary', this.colorPrimary);
            document.documentElement.style.setProperty('--color-secondary', this.colorSecondary);
        },
    },
};
