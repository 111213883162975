import './style.scss';
import { banUser, removeMessage } from '../../../../assets/Chat/Message';

export default {
    name: 'message',
    props: ['content', 'rights'],
    data() {
        return {
            assets: { banUser, removeMessage },
            message: this.content.msg,
            id: this.content.id,
            userId: this.content.userId,
            pseudo: this.content.pseudo,
            color: this.content.color,
            canModerate: this.rights,
            displayAdmin: false,
        };
    },
    created() {
    },
    methods: {
        deleteMessage() {
            this.$parent.delete(this.id);
        },
        banUser() {
            this.$parent.ban(this.userId);
        },
    },
};
