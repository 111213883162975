import './style.scss';
import Chat from '../../Chat/Chat.vue';
import { Title, InteractiveContent } from '../../shared';
import { chatService } from '../../../../services';

export default {
    name: 'ConferenceLocalViewing',
    components: { Title, Chat, InteractiveContent },
    data() {
        return {
            conference: {},
        };
    },
    mounted() {
        this.$root.$on('setConference', (conference) => { this.conference = conference; });
    },
    created() {
        this.socket = chatService.getSocket();
        this.socket.on('next-sondage', (value) => {
            this.ggdocToDisplay = value;
        });
        this.socket.on('get-sondage', (value) => {
            this.ggdocToDisplay = value;
        });
    },
};
