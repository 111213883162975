import './style.scss';
import Card from './Card/Card.vue';
import { Title } from '../shared';
import { conferenceService, dateService } from '../../../services';
import sortConferencesFromDate from './helpers/sortConferencesFromDates';

export default {
    name: 'home',
    components: { Card, Title },
    mounted() {
        this.$emit('showConferenceBanner', false);
        this.changeHeader();
    },
    data() {
        return {
            conferences: {
                previous: [],
                currents: [],
                nexts: [],
            },
        };
    },
    async created() {
        const conferences = await conferenceService.getConferences();
        const date = await dateService.getDate();
        this.conferences = sortConferencesFromDate(conferences, date);
    },
    methods: {
        changeHeader() {
            const header = document.querySelector('#banner');

            header.classList.remove('flex-col');
            header.innerHTML = `
                <p class="leading-normal text-center">
                    Communiquons.<br/>
                    Apprenons.<br/>
                    <span class="text-primary font-bold">Partageons !</span>
                </p>`;
        },
    },
};
