import './style.scss';
import { v4 as uuidv4 } from 'uuid';

import Message from './Message/Message.vue';
import { SendButton, removeUsername } from '../../../assets/Chat';
import { loginService, chatService } from '../../../services';

import isMessageValid from './helpers/isMessageValid';

export default {
    name: 'chat',
    components: { Message },
    props: ['id'],

    data() {
        return {
            assets: { button: SendButton, removeUsername },
            message: '',
            socket: null,
            messages: [],
            pseudo: this.pseudo,
            color: null,
            displayInputPseudo: false,
            isMenuDisplay: false,
            widthScreen: 0,
            canModerate: false,
        };
    },
    created() {
        const { id: socketId } = this.$route.params;
        this.socket = chatService.getSocket(socketId);
        this.socket.on('new-msg', (text) => {
            const {
                msg, pseudo, id, color, userId,
            } = text;
            this.messages.unshift({
                msg, id, pseudo, color, userId,
            });
        });

        this.socket.on('deleteMsg', (id) => {
            this.messages.forEach((element, index) => {
                if (element.id === id) this.messages.splice(index, 1);
            });
        });

        this.color = this.getRandomColor();
        if (this.$cookie.get('nickname') !== null) this.pseudo = this.$cookie.get('nickname');
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.focusMessage();
        this.checkToken();
    },
    methods: {
        clickTosend() {
            if (this.message.trim() === '') return;

            if (this.pseudo == null) {
                this.displayInputPseudo = true;
                this.focusPseudo();
                return;
            }
            this.send();
        },
        savePseudo() {
            if (this.pseudo.trim() === '') return;
            this.$cookie.set('nickname', this.pseudo, 1);
            this.displayInputPseudo = false;
            this.send();
            this.isMenuDisplay = false;
            this.focusMessage();
        },
        getRandomColor() {
            const value = (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
            return `color:#${value}`;
        },
        send() {
            this.message = this.message.trim();
            if (!isMessageValid(this.message)) return;
            const id = uuidv4();
            this.messages.unshift({
                msg: this.message,
                id,
                userId: this.socket.id,
                pseudo: this.pseudo,
                color: this.color,
            });

            this.socket.emit('msg', {
                msg: this.message,
                id,
                userId: this.socket.id,
                pseudo: this.pseudo,
                color: this.color,
            });

            this.message = '';
            this.focusMessage();
            this.isMenuDisplay = false;
        },
        delete(id) {
            this.messages.forEach((element, index) => {
                if (element.id === id) this.messages.splice(index, 1);
            });
            this.socket.emit('delete', id);
        },
        ban(userId) {
            this.socket.emit('ban', userId);
        },
        resetPseudo() {
            this.displayInputPseudo = true;
            this.focusPseudo();
        },
        displayMenu() {
            this.isMenuDisplay = !this.isMenuDisplay;
        },
        handleResize() {
            this.widthScreen = window.innerWidth;
        },
        focusMessage() {
            if (this.widthScreen >= 1279) {
                setTimeout(() => {
                    this.$refs.inputMessage.focus();
                });
            }
        },
        focusPseudo() {
            setTimeout(() => {
                this.$refs.inputPseudo.focus();
            });
        },
        async checkToken() {
            const { data } = await loginService.checkToken(localStorage.getItem('token'));
            const { type } = data;

            if (type === 'modo' || type === 'admin') this.canModerate = true;
        },
    },
};
